import React, { SyntheticEvent, useState } from "react";
import { apiUrl } from "../../config/api";
import {geocode} from '../../utils/geocoding'

import './AddForm.css';


export const AddForm = () => {
    const [loading, setLoading] = useState(false); 
    const [form, setForm] = useState({
        name: '',
        description: '',
        price: 0,
        url: '',
        addres: '',
    }); 

    const saveAd = async(e:SyntheticEvent) => {
        e.preventDefault();

        setLoading(true);

        try {
            const {lat, lon} = await geocode(form.addres);

        const res = await fetch (`${apiUrl}/ad`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body:JSON.stringify({
                ...form, 
                lat,
                lon,
            }),
        });
        const data = await res.json()
        console.log(data);

        } finally {setLoading(false)};
                
    }

    const updateForm = (key:string, value:any) => {
        setForm(form => ({
            ...form, 
            [key]: value,
        }));
    };

    if (loading) {
        return <h2>Trwa dodawanie ogłoszenia .....</h2>
    }

    return (
        <form action = "" className="add-form" onSubmit={saveAd}>
                <h1>Dodawanie ogłoszenia </h1>
                <p>
                    <label>
                        Nazwa: <br />
                        <input 
                        type="text" 
                        name="name" 
                        required 
                        maxLength={99}
                        value={form.name}
                        onChange={e => updateForm('name', e.target.value)} 
                        />
                    </label>
                </p>
                <p>
                    <label>
                        Adres: <br />
                        <input 
                        type="text" 
                        name="addres" 
                        required 
                        maxLength={99} 
                        value={form.addres}
                        onChange={e => updateForm('addres', e.target.value)} 
                        />
                    </label>
                </p>
                <p>
                    <label>
                        Opis: <br />
                        <textarea 
                        name="description" 
                        maxLength={999}
                        value={form.description}
                        onChange={e => updateForm('description', e.target.value)} 
                        ></textarea>
                    </label>
                </p>
                <p>
                    <label>
                        Cena: <br />
                        <input 
                        type="number" 
                        name="price" 
                        required 
                        maxLength={999} 
                        value={form.price}
                        onChange={e => updateForm('price', Number(e.target.value))} 
                        /> <br/>
                        <small>Pozostaw zero w polu, aby nie wyświetlać ceny </small>
                    </label>
                </p>
                <p>
                    <label>
                        Adres URL: <br />
                        <input 
                        type="url" 
                        name ="url" 
                        maxLength={99}
                        value={form.url}
                        onChange={e => updateForm('url', e.target.value)} 
                        />
                    </label>
                </p>
                <p>
                    <label>
                        Adres fizyczny na mapie: <br />
                        <input 
                        type="text" 
                        name="addres" 
                        required 
                        maxLength={99}
                        value={form.addres}
                        onChange={e => updateForm('addres', e.target.value)}  
                        />
                    </label>
                </p>
                <button className="btn">Zapisz</button>
        </form>
    )
}